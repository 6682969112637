import React, { useEffect, useState } from 'react';
import { FaAngleUp } from 'react-icons/fa';

function GoTopButton() {
  const [visible, setVisible] = useState(false);

  // Show button when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      onClick={scrollToTop}
      aria-label="Go To Top Button"
      className={`fixed bottom-5 right-6 lg:bottom-14 lg:right-10 p-3 rounded-full transition-opacity duration-300 ${
        visible ? 'opacity-90' : 'opacity-0'
      } bg-gray-200 border border-gray-500 text-gray-600 hover:border-black hover:scale-110 active:bg-gray-600`}
    >
      <FaAngleUp className="h-6 w-6"/>
    </button>
  );
}

export default GoTopButton;
